import React from 'react'
import { Link, PageProps } from 'gatsby'
import { Layout } from '../components/common/Layout'
import { container, homeLink } from '../styles/404.module.scss'

const NotFoundPage = (props: PageProps) => {
	return (
		<Layout location={[{ name: '404', path: props.location.pathname }]}>
			<main className={container}>
				<h1 className="text-8xl lg:text-9xl font-bold text-blue">404</h1>
				<p className="mt-8 text-2xl font-bold text-blue">Not Found</p>
				<p className="mt-12 leading-7 text-left lg:text-center">
					指定されたページを見つけることができませんでした。
					<br />
					ページが移動または削除されたか、URLが間違っている可能性があります。
				</p>
				<div className="mt-14">
					<Link to="/" className={homeLink}>
						<span className="z-10">TOPに戻る</span>
					</Link>
				</div>
			</main>
		</Layout>
	)
}

export default NotFoundPage
